@import '../node_modules/ol/ol.css';
@import '../node_modules/ol-geocoder/dist/ol-geocoder.css';

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
#map {
  width: 100%;
  height: 100%;
}
.ol-attribution {
  display: none;
}

.ol-geocoder .gcd-gl-btn {
  background-image: none;
  padding-top: 2px;
}

.ol-geocoder .gcd-gl-btn::after {
  content: 'search';
  font-family: Material Icons;
  /*font-weight: 400;*/
  font-style: normal;
  /*font-size: 24px;
  display: inline-block;
  line-height: 1;*/
  text-transform: none;
  letter-spacing: normal;
  /*word-wrap: normal;*/
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}